/// <reference types="@angular/localize" />
import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from '../app/app.routes';

import {
  enableProdMode,
  LOCALE_ID,
  importProvidersFrom,
  isDevMode
} from '@angular/core';

import { HttpLoaderFactory } from './app.module';
import { environment } from '../environments/environment';
import { IvyCarouselModule } from 'angular-responsive-carousel2';
import { NgxBootstrapSliderModule } from '@nahuelmorata/ngx-bootstrap-slider';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppRoutingModule } from './app.routes';
import { TransferHttpCacheModule } from '@nguniversal/common';
import {
  BrowserModule,
  HammerModule,
  provideClientHydration
} from '@angular/platform-browser';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import {
  UserTrackingService,
  ScreenTrackingService
} from '@angular/fire/compat/analytics';
import { FavoriteService } from './marketplace/service/favorite.service';
import { DatetimeService } from './marketplace/service/datetime.service';
import { UserPreferenceService } from './marketplace/service/user-preferences.service';
import { VisitorsService } from './marketplace/service/visitors.service';
import {
  SharedService,
  SelectedSportService,
  SlotService,
  CenterAvailabilitiesService,
  StateService,
  InfoPaiementService
} from './marketplace/service/shared.service';
import { ActivityService } from './marketplace/service/activity.service';
import { AuthInterceptor } from './http-auth-interceptor';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  HttpClient,
  withFetch,
  withInterceptorsFromDi
} from '@angular/common/http';
import {
  provideCloudinaryLoader,
  CommonModule,
  NgOptimizedImage
} from '@angular/common';
import { AuthGuardService } from './auth-guard.service';
import { AuthFirebaseService } from './marketplace/service/auth-firebase.service';
import { AuthService } from './shared/services/auth.service';
import { provideServiceWorker } from '@angular/service-worker';
import { initializeAppCheck, provideAppCheck, ReCaptchaEnterpriseProvider } from '@angular/fire/app-check';

declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN: boolean;
  }
}

if (environment.production) {
  enableProdMode();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    ...(environment.production ? [
      provideAppCheck(() => {
        const appCheckConfig = {
          provider: new ReCaptchaEnterpriseProvider(environment.firebase.appCheckKey),
          isTokenAutoRefreshEnabled: true,
        };
        return initializeAppCheck(getApp(), appCheckConfig);
      })
    ] : []),
    provideAuth(() => getAuth()),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    importProvidersFrom(
      BrowserModule,
      TransferHttpCacheModule,
      HammerModule,
      AppRoutingModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      GoogleMapsModule,
      NgxBootstrapSliderModule,
      IvyCarouselModule,
      NgOptimizedImage
    ),
    AuthService,
    AuthFirebaseService,
    AuthGuardService,
    provideCloudinaryLoader('https://res.cloudinary.com/anybuddy/'),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'fr' },
    ActivityService,
    SharedService,
    SelectedSportService,
    SlotService,
    VisitorsService,
    CenterAvailabilitiesService,
    UserPreferenceService,
    DatetimeService,
    StateService,
    InfoPaiementService,
    FavoriteService,
    UserTrackingService,
    ScreenTrackingService,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: 'googleTagManagerId', useValue: environment.gtmId },
    { provide: '303254103213707', useValue: 'websitebooking' },
    provideClientHydration(), //provide app_id
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
};
